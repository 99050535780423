<script>
import PageHeader from "@/components/page-header.vue";
import AvrTable from "@/views/pages/avr/components/AvrList.vue";

export default {
  name: "Index",
  components: {
    PageHeader,
    AvrTable
  },
  data() {
    return {
      title: 'AVR',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "AVR List",
          active: true,
        },
      ],
    }
  }
}
</script>

<template>
  <PageHeader :title="title" :items="items" />
  <AvrTable/>
</template>

<style scoped>

</style>