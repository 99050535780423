<script>
import Table from "@/components/tables/table.vue";

export default {
  name: "AvrList",
  components: {
    Table
  },
  data() {
    return {
      table: {
        headers: [
          {
            label: 'Avr Name',
            field: 'name',
            align: 'center',
          },
          {
            label: 'Contract',
            field: 'contract',
            align: 'center',
            visible: false
          },
          {
            label: 'Customer',
            field: 'customer',
            align: 'center',
          },
          {
            label: 'Orders',
            field: 'orders',
            align: 'center',
            maxWidth: '120px'
          },
          {
            label: 'File',
            field: 'file',
            align: 'center',
            searchable: false
          },
          {
            label: 'Total Agreed Rate',
            field: 'total_agreed_rate',
            align: 'center',
            searchable: false
          },
          {
            label: 'Quantity',
            field: 'total_quantity',
            align: 'center',
            searchable: false
          },
          {
            label: 'Service Name',
            field: 'service_name',
            align: 'center',
          },
          {
            label: 'Number',
            field: 'number',
            align: 'center',
          },
          {
            label: 'Date',
            field: 'date',
            align: 'center',
          },
          {
            label: 'Customer Director Name',
            field: 'customer_director_name',
            align: 'center',
            visible: false
          },
        ],
        url: '/contract/act_performed_work/list/',
        searchable: true,
      },
      ORDER_CHILD_TYPE: {
        container_order: {
          order_route_name: 'orders_container_detail',
          avr_route_name: 'avr_container_details'
        },
        wagon_order: {
          order_route_name: 'orders_wagon_detail',
          avr_route_name: 'avr_wagon_details'
        },
        wagon_empty_order: {
          order_route_name: 'orders_empty_wagon_detail',
          avr_route_name: 'avr_empty_wagon_details'
        },
      },
    }
  },
  computed: {
    searched_order_number() {
      return this.$route.query.orders || ''
    }
  },
  methods: {
    formatPrice(price) {
      if (price) {
        return parseFloat(price).toLocaleString('en-UK', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })
      }
      return 0
    },
    downloadFile(path) {
      if (!path) return;
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },
  }
}
</script>

<template>
  <div class="mb-3">
    <Table v-bind="table">
      <template v-slot:top-right>
        <div class="btn-group">
          <button type="button" class="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            New AVR
          </button>
          <div class="dropdown-menu dropdownmenu-success">
            <router-link :to="{name: 'avr_create_container'}" class="dropdown-item">Container</router-link>
            <router-link :to="{name: 'avr_create_wagon'}" class="dropdown-item">Wagon</router-link>
            <router-link :to="{name: 'avr_create_empty_wagon'}" class="dropdown-item">Empty Wagon</router-link>
          </div>
        </div><!-- btn-group -->
      </template>
      <template v-slot:name="{row: data}">
        <router-link v-if="ORDER_CHILD_TYPE[data.order_type]" :to="{
        name: ORDER_CHILD_TYPE[data.order_type].avr_route_name, params: {
          avr_id : data.id
        }
      }">{{ data.name }}
        </router-link>
        <span v-else>{{ data.name }}</span>
      </template>
      <template v-slot:contract="{row: data}">
        {{ data.contract ? data.contract.name : '' }}
      </template>
      <template v-slot:file="{row: data}">
        <div @click="downloadFile(data.file)"
             v-if="data.file"
             class="d-flex align-items-center gap-2 justify-content-center cursor-pointer text-truncate">
          <i class="mdi mdi-file-excel fs-4 text-success"></i>
          <span style="max-width: 150px" class="text-decoration-underline text-truncate">{{
              data.file.split("/").pop()
            }}</span>
        </div>
      </template>
      <template v-slot:customer="{row: data}">
        {{ data.contract ? data.contract.company.name : '' }}
      </template>
      <template v-slot:total_agreed_rate="{row: data}">
        {{ formatPrice(data.total_agreed_rate) }}
      </template>
      <template v-slot:total_quantity="{row: data}">
        {{ data.total_quantity ? data.total_quantity : 0 }}
      </template>
      <template v-slot:orders_td="{row: data}">
        <td style="min-width: 250px; max-width: 260px">
          <div v-if="data.orders && (data.orders || []).length"
               class="d-flex flex-wrap gap-3 mx-auto justify-content-center">
            <router-link
                v-for="order in (data.orders || [])" :key="`order_${order.id}`"
                :to="{
                name: ORDER_CHILD_TYPE[order.child_type].order_route_name, params: {
                  id: order.order_number
                }
              }"
                class="badge"
                :class="{
                'bg-secondary': order.order_number.toString().includes(searched_order_number || '-1'),
                'bg-soft-secondary text-secondary': !order.order_number.toString().includes(searched_order_number || '-1'),
              }"
            >
              {{ order.order_number }}
            </router-link>
          </div>
          <div class="text-center" v-else>-</div>
        </td>
      </template>
      <template v-slot:service_name_td="{row:data}">
        <td style="max-width: 300px">
          <p v-b-tooltip.hover :title="data.service_name" class="text-truncate mb-0">
            {{ data.service_name }}
          </p>
        </td>
      </template>
    </Table>
  </div>
</template>

<style scoped>

</style>